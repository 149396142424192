import React from 'react';
import { css } from 'emotion';
import ContactForm from './ContactForm';

const styles = css`
  padding-top: 5vw;
  padding-bottom: 10vw;
  background-color: #FFF;

  .container {
    max-width: 670px;
  }
  textarea {
    min-height: 9.5rem;
  }

  @media (min-width: 1024px) {
    padding-top: 3em;
    padding-bottom: 6em;
  }
`;

export default props => (
  <section className={styles}>
    <div className="container content">
      <h1>Questions?</h1>
      {/* eslint-disable-next-line */}
      <p>Check out our <a href="#" onClick={e => (e.preventDefault(), props.toggleQuestions())}>Frequently Asked Questions.</a> If that doesn't cover it, send us a message. We'd love to hear from you!</p>
      <ContactForm />
    </div>
  </section>
);