import React from 'react';
import { cx } from 'emotion';
import { actions } from '../store';
import { isEmail, isEmpty } from '../utils';
import { useForm, useToggle } from '../utils/hooks';
import Button from '../components/Button';

export default props => {

  const { values, errors, onChange, onSubmit } = useForm({}, validate, submit);
  const [ isLoading, toggleLoading ] = useToggle();
  const [ isSubmitted, toggleSubmitted ] = useToggle();

  function submit() {
    toggleLoading();
    actions.contact(values).then(
      res => toggleSubmitted(),
      err => toggleLoading()
    );
  }

  function validate(values) {
    const errors = {};

    if (isEmpty(values.name)) {
      errors.name = 'Required';
    }
    if (!isEmail(values.email)) {
      errors.email = 'Required';
    }
    if (isEmpty(values.message)) {
      errors.message = 'Required';
    }

    return errors;
  }

  if (isSubmitted) {
    return <h4>Thanks for contacting us. We'll be in touch soon!</h4>
  }

  return (
    <form onSubmit={onSubmit}>

      <div className={cx('field', errors.name && 'has-error')}>
        <input
          type="text"
          name="name"
          value={values.name || ''}
          onChange={onChange}
          className="input"
          placeholder="Name"
        />
      </div>
      <div className={cx('field', errors.email && 'has-error')}>
        <input
          type="email"
          name="email"
          value={values.email || ''}
          onChange={onChange}
          className={`input ${errors.email && 'has-error'}`}
          placeholder="Email"
        />
      </div>
      <div className={cx('field', errors.message && 'has-error')}>
        <textarea
          name="message"
          value={values.message || ''}
          onChange={onChange}
          className={`input ${errors.message && 'has-error'}`}
          placeholder="Message"
        />
      </div>

      <Button type="submit" className="secondary" disabled={isLoading}>
        {isLoading ? 'Sending' : 'Send Message'}
      </Button>

    </form>
  );
}