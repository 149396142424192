import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from '../store';
import { isEmpty } from '../utils';
import { useToggle } from '../utils/hooks';
import HomeHero from '../components/HomeHero';
import HomeCallouts from '../components/HomeCallouts';
import HomeContact from '../components/HomeContact';
import HomeFooter from '../components/HomeFooter';
import HomePreview from '../components/HomePreview';
import HomeQuestions from '../components/HomeQuestions';
import HomeSteps from '../components/HomeSteps';
import LoginModal from '../components/LoginModal';
import OrderForm from '../components/OrderForm';
import Sidebar from '../components/Sidebar';
import Modal from '../components/Modal';

const Home = props => {

  const {
    isAuthed,
    isPassholder,
    isLoginActive,
    isOrderActive,
    toggleLogin,
    toggleOrder
  } = props;

  const [
    isQuestionsActive,
    toggleQuestions
  ] = useToggle();

  function onOrderClose() {
    toggleOrder();
    props.history.replace('/');
  }

  function onLogin() {
    props.onLogin();
    props.history.push('/offers');
  }

  const loginModal = isAuthed ? null :
    <Modal in={isLoginActive} mountOnEnter={true} unmountOnExit={true} closeOnClick={!props.match.params.token} close={toggleLogin}>
      <LoginModal
        token={props.match.params.token}
        close={toggleLogin}
        onLogin={onLogin}
      />
    </Modal>

  return (
    <>
      <HomeHero toggleOrder={toggleOrder} />
      <HomeCallouts />
      <HomePreview />
      <HomeSteps toggleQuestions={toggleQuestions} />
      <HomeContact toggleQuestions={toggleQuestions} />
      <HomeFooter toggleOrder={toggleOrder} />

      <Sidebar in={isOrderActive} closeOnClick={false} mountOnEnter={true} unmountOnExit={false} close={onOrderClose} width={768}>
        {isPassholder === false ? <OrderForm /> : (
          <div className="content">
            <h1>Good News!</h1>
            <p>You're already a Discovery Pass holder - click the menu icon to access your available offers. Please note that we only allow one seasonal pass per email. New passes are released in May and November each year. Get in touch if you have questions!</p>
            <p><a href="mailto:info@discoverypass.co" className="cta-link">info@discoverypass.co</a></p>
          </div>
        )}
      </Sidebar>

      <Sidebar in={isQuestionsActive} mountOnEnter={true} unmountOnExit={true} close={toggleQuestions}>
        <HomeQuestions toggleQuestions={toggleQuestions} />
      </Sidebar>

      {loginModal}
    </>
  );
};

export default withRouter(connect(state => ({
  isAuthed: state.isAuthed,
  isPassholder: !isEmpty(state.coupons)
}))(Home));
