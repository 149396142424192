import createStore from 'react-waterfall';
import axios from 'axios';
import { isEmpty, shuffle } from './utils';

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

const now = new Date();

const initialState = {
  categories: ['Eats', 'Drinks', 'Shops', 'Experiences', 'Lodging'],
  coupons: [],
  user: null,
  isAuthed: false,
  isLoading: true,
  isOffline: !!navigator.onLine === false,
  today: [
    now.getFullYear(),
    String(now.getMonth() + 1).padStart(2, '0'),
    String(now.getDate()).padStart(2, '0')
  ].join('-')
};

function getCoupons(coupons) {
  return isEmpty(coupons) ? null : shuffle(coupons).filter(coupon => coupon.category);
}

const actionsCreators = {

  purchase(state, actions, data) {
    return axios.post(`/purchase/${process.env.REACT_APP_PRODUCT_ID}`, data)
  },

  setStatus(state, actions) {
    const isOffline = !!navigator.onLine === false;
    return {isOffline};
  },

  setLoading(state, actions, isLoading) {
    return {isLoading};
  },

  setUser(state, actions, response) {

    if (isEmpty(response)) {
      delete axios.defaults.headers.common['Authorization'];
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return {isAuthed: false, user: null};
    }

    let { user, token } = response;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));

    return {isAuthed: true, user};
  },

  verify(state, actions) {

    let token = localStorage.getItem('token');

    if (isEmpty(token)) {
      return {isLoading: false};
    }

    return axios.get(`/users/me`, {headers: {'Authorization': `Bearer ${token}`}})
      .then(res => {
        actions.setUser(res.data);
        return {isLoading: false, coupons: getCoupons(res.data.coupons)};
      })
      .catch(err => {
        actions.setUser(null);
        return {isLoading: false};
      });
  },

  login(state, actions, data) {
    return axios.post(`/users/login`, data).then(res => {
      actions.setUser(res.data);
      return {isLoading: false, coupons: getCoupons(res.data.coupons)};
    });
  },

  logout(state, actions, data) {
    actions.setUser(null);
    window.location.replace('/');
    return Promise.resolve();
  },

  forgotPassword(state, actions, data) {
    return axios.post(`/users/reset`, data);
  },

  resetPassword(state, actions, data) {
    return axios.patch(`/users/reset`, data);
  },

  favorite(state, actions, id) {
    const coupons = state.coupons;
    const coupon = coupons.find(c => c.id === id);
    axios({
      method: coupon.favorite ? 'DELETE' : 'POST',
      url: `/coupons/${id}/favorites`
    });
    coupon.favorite = !coupon.favorite;
    return {coupons};
  },

  redeem(state, actions, id) {
    const coupons = state.coupons;
    const coupon = coupons.find(c => c.id === id);
    axios.post(`/coupons/${id}/redeem`);
    coupon.redeemed = true;
    coupon.redemptions.push(state.today);
    return {coupons};
  },

  account(state, actions, data) {
    return null;
  },

  contact(state, actions, data) {
    data.content = data.message;
    delete data.message;
    return axios.post('/contact', data);
  }
};

export const { Provider, connect, actions } = createStore({
  initialState,
  actionsCreators
});
