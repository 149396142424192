import React from 'react';
import { cx } from 'emotion';
import { actions } from '../store';
import { isEmail } from '../utils';
import { useForm, useToggle } from '../utils/hooks';
import Button from '../components/Button';

function LoginForgotForm(props) {

  const { values, errors, setErrors, onChange, onSubmit } = useForm({}, validate, submit);
  const [ isLoading, toggleLoading ] = useToggle();
  const disabled = !isEmail(values.email) || isLoading;

  function submit() {
    toggleLoading();
    actions.forgotPassword(values).then(
      res => {
        props.close();
      },
      err => {
        setErrors({email: err.message || 'Error finding account'});
        toggleLoading();
      },
    );
  }

  function validate(values) {
    const errors = {};
    if (!isEmail(values.email)) {
      errors.email = 'Email address is invalid';
    }
    return errors;
  }

  return (
    <form onSubmit={onSubmit} autoComplete="off" noValidate={true}>

      <p className="mb-1">Enter the email you purchased your pass with and we'll send a link to reset your password.</p>

      <div className={cx('field', errors.email && 'has-error')}>
        <input
          type="email"
          name="email"
          value={values.email || ''}
          onChange={onChange}
          className={`input ${errors.email && 'has-error'}`}
          placeholder="Email Address"
          required
        />
        {errors.email && <span className="help">{errors.email}</span>}
      </div>

      <div className="text-center">
        <Button type="submit" className="w-100 mb-1" disabled={disabled}>
          {isLoading ? 'Sending' : 'Send reset link'}
        </Button>
        {/* eslint-disable-next-line */}
        <small><a href="#" onClick={e => (e.preventDefault(), props.showLogin())}>Back to login</a></small>
      </div>

    </form>
  );
};

export default LoginForgotForm;