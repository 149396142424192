import React from 'react';
import axios from 'axios';
import { css, cx } from 'emotion';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { actions, connect } from '../store';
import { colors, breakpoints } from '../styles';
import { isEmail, isEmpty, isString } from '../utils';
import Button from './Button';

// TODO: get price from a product API endpoint
const PRODUCT_PRICE = 25;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const styles = css`

  .dob {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .dob-fields {
    display: flex;
    justify-content: space-between;

    select {
      flex: 1 1 50%;
    }
    input {
      flex: 1 1 25%;
      margin-left: 0.625rem;
      max-width: 100px;
      text-align: right;
    }
  }

  .StripeElement {
    border: rgba(${colors.rgb.brownLight},0.25) 1px solid;
    height: 2.85rem;
    max-height: 56px;
    background-color: #FFF;
    transition: all 0.125s;
    padding: 0.7rem 0.625em 0.625em 0.625em;
  }
  .StripeElement--disabled {
    opacity: 0.4;
    background-color: transparent;
    pointer-events: none;
  }
  .StripeElement--focus {
    border-color: ${colors.orange};
  }
  .StripeElement--invalid {
    border-color: ${colors.red};
    box-shadow: 0 0 0 0.15em rgba(${colors.rgb.orange},0.15);
  }

  .card-fields {
    display: flex;
    justify-content: space-between;

    > div {
      width: 100%;
      max-width: 32%;
    }
  }

  .promo-apply {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    text-indent: -9999px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 352'%3E%3Cpath fill='%233D2C11' fill-rule='evenodd' d='M176 308c-72.6 0-132-59.4-132-132S103.4 44 176 44c36.3 0 69.3 15.4 92.4 39.6L198 154h154V0l-51.7 51.7C268.4 19.8 224.4 0 176 0 79.2 0 0 79.2 0 176s78.1 176 176 176c81.05 0 148.29-54.13 169.4-128h-46.55c-18.75 49.56-67.14 84-122.85 84z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    transition: opacity 0.2s;
    opacity: 0.3;
  }
  .promo-apply:hover {
    opacity: 0.75;
  }

  .order-total {
    font-weight: 400;
    color: ${colors.brownLight};
    margin-right: 5px;
  }
  .order-total > span {
    color: ${colors.orangeDark};
  }

  .button[type="submit"] {
    margin-top: 1.25em;
  }
  .desktop & .button[href="/login"] {
    display: none;
  }
`;

const stripeElementStyles = {
  base: {
    fontFamily: 'Barlow, sans-serif',
    fontSize: '22px',
    fontSmoothing: 'antialiased',
    fontWeight: 500,
    color: colors.brownDark,
    '::placeholder': {
      fontWeight: 400,
      color: colors.brownLight,
    },
    '::-ms-clear': {
      display: 'none'
    }
  },
  invalid: {
    color: colors.brownDark
  },
};

class OrderForm extends React.Component {

  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onResize = this.onResize.bind(this);
    this.elements = {};
    this.dobYear = React.createRef();

    const user = props.user || {};
    const [ dobYear, dobMonth, dobDay ] = user.dob ? user.dob.split('-') : [];
    const params = new URLSearchParams(window.location.search);

    let prefill = window.sessionStorage.getItem('_promo');

    if (prefill) {
      try {
        prefill = JSON.parse(window.atob(prefill));
        params.set('promo', prefill.code);
      } catch (e) {
        prefill = null;
        window.sessionStorage.removeItem('_promo');
      }
    }

    this.initialState = {
      isExistingUser: !isEmpty(user),
      name: user.name || (prefill && prefill.name) || '',
      email: user.email || (prefill && prefill.email) || '',
      emailConfirm: user.email || (prefill && prefill.email) || '',
      password: '',
      passwordConfirm: '',
      dobYear: dobYear || '',
      dobMonth: dobMonth || '',
      dobDay: dobDay || '',
      dobDayMax: 31,
      hasPromo: params.has('promo'),
      hasHiddenPromo: !isEmpty(prefill),
      promo: params.get('promo') || '',
      zip: user.zip || '',
      orderTotal: PRODUCT_PRICE,
      agree: false,
      loading: false,
      success: false,
      errors: {}
    };

    this.state = this.initialState;
  }

  componentDidMount() {

    if (this.state.hasPromo) {
      this.validatePromo();
    }

    window.removeEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    const width = window.innerWidth;
    let fontSize;

    if (width > breakpoints.lg) {
      fontSize = 20;
    } else if (width > breakpoints.md) {
      fontSize = 18;
    } else if (width > breakpoints.xs) {
      fontSize = 16;
    } else {
      fontSize = 15;
    }

    stripeElementStyles.base.fontSize = `${fontSize * 1.1}px`;
  }

  onBlur(event) {

    const name = event.target.name;
    const value = event.target.value;

    if (name === 'dobDay' && value.trim() && value.length < 2) {
      this.setState({dobDay: value.padStart(2, 0)});
    } else if (name === 'promo') {
      this.validatePromo();
    }
  }

  onChange(event) {

    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({[name]: value});

    if (name === 'dobMonth' || name === 'dobYear') {

      const month = name === 'dobMonth' ? value : this.state.dobMonth;
      const year = name === 'dobYear'  ? value : this.state.dobYear;
      const dobDayMax = new Date(year, month, 0).getDate();

      this.setState({
        dobDayMax,
        dobDay: this.state.dobDay > dobDayMax ? dobDayMax : this.state.dobDay
      });
    }

    if (name === 'dobDay' && value.length === 2) {
      this.dobYear.current.focus();
    } else if (name === 'dobYear' && value.length > 4) {
      this.setState({dobYear: value.slice(0, 4)});
    }
  }

  async onSubmit(event) {

    event.preventDefault();
    const errors = this.validate(this.state);

    if (Object.keys(errors).length) {
      this.setState({errors});
      return;
    }

    this.setState({loading: true, errors: {}});

    const { name, email, password, zip, dobYear, dobMonth, dobDay } = this.state;
    let token;

    if (!this.isFree()) {

      const payload = await this.props.stripe.createToken(this.elements.cardNumber, {
        name,
        address_zip: zip,
        address_country: 'US'
      });

      if (payload.error) {
        errors.card = payload.error.message;
        this.setState({loading: false, errors});
        return;
      }

      token = payload.token.id;
    }

    const data = {name, email, password, zip, dob: `${dobYear}-${dobMonth}-${dobDay}`};

    this.state.hasPromo && (data.promo = this.state.promo);
    token && (data.token = token);
    data.email = email.toLowerCase();
    data.newsletter = true;

    actions.purchase(data).then(
      res => {
        for (let key in this.elements) this.elements[key].clear();
        this.setState({...this.initialState, success: true});
        window.sessionStorage.removeItem('_promo');
        if (this.state.isExistingUser) window.location = '/offers';
      },
      err => {
        this.setState({loading: false, errors: err.message})
      }
    );
  }

  validate(data) {

    const { name, email, emailConfirm, password, passwordConfirm, hasPromo, promo, dobMonth, dobDay, dobYear, zip, agree } = data;
    const errors = {};

    if (isEmpty(name.trim())) {
      errors.name = 'First name is required';
    }

    if (isEmpty(email.trim())) {
      errors.email = 'Email is required';
    } else if (!isEmail(email)) {
      errors.email = 'Invalid email address';
    } else if (email !== emailConfirm) {
      errors.emailConfirm = 'Email does not match';
    }

    if (isEmpty(password.trim())) {
      errors.password = 'Password is required';
    } else if (password.length < 4) {
      errors.password = 'Please enter a password with at least 4 characters';
    } else if (password !== passwordConfirm) {
      errors.passwordConfirm = 'Password does not match';
    }

    if (isEmpty(dobMonth) || isEmpty(dobDay) || isEmpty(dobYear) || dobYear.length < 4 || dobYear > (new Date().getFullYear())) {
      errors.dob = 'Please enter your date of birth as Month DD YYYY';
    }

    const age = (new Date()).getFullYear() - Number(dobYear);
    if (age < 18 || age > 100) {
      errors.dob = 'Please enter your real date of birth';
    }

    if (zip.length !== 5) {
      errors.zip = 'Invalid zip code';
    }

    if (hasPromo && isEmpty(promo)) {
      errors.promo = 'Please enter your promo code';
    }

    if (agree !== true) {
      errors.agree = 'Please agree to the terms of use';
    }

    return errors;
  }

  validatePromo() {
    const code = this.state.promo.trim();

    if (isEmpty(code)) {
      this.setState({
        orderTotal: PRODUCT_PRICE,
        errors: {...this.state.errors, promo: null}
      });
      return;
    }

    axios.get(`/purchase/${process.env.REACT_APP_PRODUCT_ID}/promo?code=${code}`).then(
      res => this.setState({
        orderTotal: Number(res.data),
        errors: {...this.state.errors, promo: null}
      }),
      err => this.setState({
        orderTotal: PRODUCT_PRICE,
        errors: {...this.state.errors, promo: err.message || 'Invalid promo code'},
        hasHiddenPromo: false
      })
    );
  }

  isFree() {
    return this.state.hasPromo && this.state.orderTotal === 0;
  }

  render() {

    const {
      isExistingUser,
      loading,
      success,
      errors,
      name,
      email,
      emailConfirm,
      password,
      passwordConfirm,
      dobMonth,
      dobDay,
      dobDayMax,
      dobYear,
      hasPromo,
      hasHiddenPromo,
      promo,
      zip,
      orderTotal,
      agree
    } = this.state;

    const isFree = this.isFree();

    return (
      <form className={cx(styles, 'content')} onSubmit={this.onSubmit} autoComplete="off" noValidate={true}>

        <h1>Get Your Pass</h1>
        {/* <p>You'll need a smartphone to use Discovery Pass. Fill out the form below and we'll send you an email with how to get started. Interested in giving Discovery Pass as a gift?<br className="md:hide" /> <a href="https://gift.discoverypass.co/">Click here!</a></p> */}
        <p>You'll need a smartphone to use Discovery Pass. Fill out the form below and we'll send you an email with how to get started.</p>

        {isString(errors) && (
          <p className="error">{errors}</p>
        )}

        {success ? (
          <React.Fragment>
            <p className="lead">Success! One last step. Use your mobile device to log in and start using your Discovery Pass.</p>
            <Button href="/login" className="md:hide">Click Here to Log In</Button>
          </React.Fragment>
        ) : (
        <React.Fragment>

          <div className={cx('field', errors.name && 'has-error')}>
            <label className="label hidden" htmlFor="order-name">Name</label>
            <input className="input" type="text" name="name" id="order-name" maxLength="70" value={name} placeholder="Name" onChange={this.onChange} required />
            {errors.name && <span className="help">{errors.name}</span>}
          </div>

          <div className={cx('field', errors.email && 'has-error')}>
            <label className="label hidden" htmlFor="order-email">Email</label>
            <input className="input" type="email" name="email" id="order-email" maxLength="70" value={email} placeholder="Email" onChange={this.onChange} required />
            {errors.email && <span className="help">{errors.email}</span>}
          </div>

          <div className={cx('field', errors.emailConfirm && 'has-error')}>
            <label className="label hidden" htmlFor="order-emailConfirm">Confirm Email</label>
            <input className="input" type="email" name="emailConfirm" id="order-emailConfirm" value={emailConfirm} placeholder="Confirm Email" onChange={this.onChange} required />
            {errors.emailConfirm && <span className="help">{errors.emailConfirm}</span>}
          </div>

          <div className={cx('field', errors.password && 'has-error')}>
            <label className="label hidden" htmlFor="order-password">Password</label>
            <input className="input" type="password" name="password" id="order-password" maxLength="70" value={password} placeholder={isExistingUser ? 'New Password' : 'Password'} onChange={this.onChange} required />
            {errors.password && <span className="help">{errors.password}</span>}
          </div>

          <div className={cx('field', errors.passwordConfirm && 'has-error')}>
            <label className="label hidden" htmlFor="order-passwordConfirm">Confirm Password</label>
            <input className="input" type="password" name="passwordConfirm" id="order-passwordConfirm" value={passwordConfirm} placeholder={isExistingUser ? 'Confirm New Password' : 'Confirm Password'} onChange={this.onChange} required />
            {errors.passwordConfirm && <span className="help">{errors.passwordConfirm}</span>}
          </div>

          <div className={cx('field', 'dob', errors.dob && 'has-error')}>
            <label className="label">Birthday</label>
            <div className="dob-fields">
              <select name="dobMonth" value={dobMonth} onChange={this.onChange}>
                <option value="" disabled>Month</option>
                <option value="01">Janaury</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <input className="input" type="number" pattern="[0-9]*" min="1" max={dobDayMax} name="dobDay" id="order-dobDay" value={dobDay} placeholder="DD" onChange={this.onChange} onBlur={this.onBlur} required />
              <input className="input" type="number" pattern="[0-9]*" ref={this.dobYear} name="dobYear" id="order-dobYear" value={dobYear} placeholder="YYYY" onChange={this.onChange} required />
            </div>
            {errors.dob && <span className="help">{errors.dob}</span>}
          </div>

          <label className="label flex justify-between items-center" htmlFor="order-payment">
            <div className={cx(hasHiddenPromo && 'invisible')}>
              Payment Information
            </div>
            <small className="order-total">
              {isFree ? <span>No credit card needed</span> : <React.Fragment>Your card will be charged <strong>${hasPromo ? orderTotal : PRODUCT_PRICE}</strong></React.Fragment>}
            </small>
          </label>

          <div className={cx('field', errors.promo && 'has-error', hasHiddenPromo && 'hide')}>
            <div className="flex justify-between items-center">
              <div className="flex-1-0-auto">
                  <input type="checkbox" name="hasPromo" id="order-hasPromo" checked={hasPromo} onChange={this.onChange} required />
                  <label htmlFor="order-hasPromo">I have a promo code</label>
              </div>
              <div className={cx('ml-2 flex-shrink relative', hasPromo || 'invisible')} style={{width:'100%',maxWidth:300}}>
                <label className="label hidden">Promo Code</label>
                <input className="input" type="text" name="promo" id="order-promo" value={promo} onChange={this.onChange} onBlur={this.onBlur} placeholder="Code" required />
                <button type="button" className="promo-apply hide sm:show">Apply</button>
              </div>
            </div>
            {(hasPromo && errors.promo) && <span className="help text-right">{errors.promo}</span>}
          </div>

          <CardNumberElement
            className={cx('field', 'StripeElement', isFree && 'StripeElement--disabled')}
            options={{style: stripeElementStyles, placeholder: 'Card Number', disabled: isFree}}
            onReady={el => this.elements.cardNumber = el}
          />

          <div className={cx('field', (errors.card || errors.zip) && 'has-error')}>
            <div className="card-fields">
              <CardExpiryElement
                className={cx('StripeElement', isFree && 'StripeElement--disabled')}
                options={{style: stripeElementStyles, disabled: isFree}}
                onReady={el => this.elements.cardExpiry = el}
              />
              <CardCvcElement
                className={cx('StripeElement', isFree && 'StripeElement--disabled')}
                options={{style: stripeElementStyles, disabled: isFree}}
                onReady={el => this.elements.cardCvc = el}
              />
              <div className={cx('field', 'card-zip', errors.zip && 'has-error')}>
                <label className="label hidden">Zip Code</label>
                <input className="input" type="text" pattern="[0-9]*" maxLength="5" name="zip" id="order-zip" value={zip} onChange={this.onChange} placeholder="Zip" required />
              </div>
            </div>
            {(errors.card || errors.zip) && <span className="help">{errors.card || errors.zip}</span>}
          </div>

          <div className="field" style={{marginTop: '1.5rem'}}>
              <input type="checkbox" name="agree" id="order-agree" checked={agree} onChange={this.onChange} required />
              <label htmlFor="order-agree">I have read and agree to the Terms of Use</label>
          </div>

          <Button type="submit" disabled={!agree || loading}>{loading ? 'Processing' : 'Submit Order'}</Button>

        </React.Fragment>)}

      </form>
    );
  }
}

const ConnectedOrderForm = connect((state, props) => ({
  user: state.user
}))(OrderForm);

const InjectedOrderForm = () => (
  <ElementsConsumer>
    {({stripe, elements}) => <ConnectedOrderForm stripe={stripe} elements={elements} />}
  </ElementsConsumer>
);

export default () => (
  <Elements stripe={stripePromise} options={{fonts: [{cssSrc: 'https://fonts.googleapis.com/css?family=Barlow:400,500'}]}}>
    <InjectedOrderForm />
  </Elements>
);
