import React from 'react';
import { useToggle } from '../utils/hooks';
import LoginForm from './LoginForm';
import LoginForgotForm from './LoginForgotForm';
import LoginResetForm from './LoginResetForm';

export default props => {
  const [ isForgotActive, toggleForgot ] = useToggle();

  if (props.token) {
    return <LoginResetForm close={props.close} token={props.token} />
  }

  return isForgotActive
    ? <LoginForgotForm close={props.close} showLogin={toggleForgot} />
    : <LoginForm close={props.close} showForgot={toggleForgot} onLogin={props.onLogin} />
};